.navigation {
  height: 60px;
  width: 100%;
  display: flex;
  position: relative;
  padding: 0.5rem 0rem;
  background-color: #fff;
  color: black;
  box-shadow: 0 2px 0px 2px rgba(9, 9, 9, 0.23);
  text-align: center;
  justify-content: center;
  align-items: center;
}

  .navigation-menu ul {
    display: flex;
    padding: 0;
  }
  .navigation-menu li {
    list-style-type: none;
    margin: 0 1rem;
  }
  .navigation-menu li a {
    text-decoration: none;
    display: block;
    width: 100%;
  }
.hamburger-line {
  width: 30px;
  height: 3px;
  background-color: rgb(134, 133, 133);
  margin: 5px;
  transition: all 0.3s ease-in-out;
}

.hamburger {
  border: 0;
  height: 55px;
  width: 55px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  display: none;
}
.hamburger:hover {
  background-color: #e6e5e5;
}

@media screen and (max-width: 768px) {
    .navigation-menu.expanded ul {
        display: block;
      }
      .hamburger {
        display: block;
      }
    
      .navigation-menu ul {
        display: none;
      }
      
    
    .navigation-menu ul {
      position: absolute;
      display: none;
      top: 60px;
      left: 0;
      flex-direction: column;
      width: 100%;
      height: calc(100vh - 77px);
      background-color: white;
      border-top: 1px solid black;
    }
    .navigation-menu li {
      text-align: center;
      margin: 0;
    }
    .navigation-menu li a {
      color: black;
      width: 100%;
      padding: 1.5rem 0;
    }
    .navigation-menu li:hover {
      background-color: #eee;
    }
  }

  .home-headingw{
    color: black;
    font-weight: 500;
    font-size: px;
    font-family: Inter, sans-serif
  }

  