.home-box{
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.8rem;
}

.dot-background {
    background-color: #f7f7f7; /* Light background color */
    background-image: radial-gradient(#ccc 1px, transparent 1px);
    background-size: 25px 25px; /* Adjust the size of dots and spacing */
    min-height: 100vh; /* Full height of the viewport */
    width: 100%;
  }