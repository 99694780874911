.about-me-title{
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.5;   
}

.about-me-container{

}

.about-me-text{
    font-size: 16px;
    font-family: Inter, sans-serif;
}

.about-me{
    font-weight: 400;
    font-size: 16px;
    line-height: 2;   
    margin-bottom: 1rem;

}
